import React, { useEffect, useState } from "react"
import "./Error.sass"
import imageLogin from "../../static/login-image.png"
import imageLogo from "../../static/logo-adigital-white.png"

const Error = () => {
  useEffect(() => {
    localStorage.removeItem("accessToken")
  })

  return (
    <div id="login">
      <div className="logo">
        <img src={imageLogo} />
      </div>

      <div className="login-content">
        <div className="form-login">
          <div className="form-content">
            <h2>Acceso no registrado</h2>
            <p>
              No estás registrado como Asociado de Adigital para acceder al Área Privada de Espacio Adigital. Para
              continuar con el proceso, contacta con nosotros en{" "}
              <a href="mailto: info@adigital.org">info@adigital.org</a>.
            </p>
            <p>
              ¿No eres Asociado de Adigital? Únete a la mayor red de empresas de la economía digital.{" "}
              <a href="https://www.adigital.org/contacto/">Solicita información</a>.
            </p>
          </div>
        </div>
        <div className="login-image">
          <img src={imageLogin} />
        </div>
      </div>
    </div>
  )
}

export default Error
