import React, { useEffect, useState } from "react"
import "./EventManualInvite.sass"
import { useLocation, useParams } from "react-router-dom"
import imageLogin from "../../static/login-image.png"
import imageLogo from "../../static/logo-adigital-white.png"
import { sendManualInvite } from "../../hooks/api"

const EventManualInvite = () => {
  const useQuery = () => {
    const { search } = useLocation()
    return React.useMemo(() => new URLSearchParams(search), [search])
  }
  let { id } = useParams()
  let [ok, setOk] = useState()
  let [formMail, setFormMail] = useState("")
  let [formCompany, setFormCompany] = useState("")
  let [formName, setFormName] = useState("")
  let [formSurname, setFormSurname] = useState("")
  let [formPosition, setFormPosition] = useState("")

  let query = useQuery()
  const email = query.get("email")

  useEffect(async () => {
    if (email) {
      const res = await sendManualInvite({
        email: email,
        event_id: id,
      })
      console.log(res)
    }
  })

  if (email || ok) {
    return (
      <div id="login">
        <div className="logo">
          <img src={imageLogo} />
        </div>

        <div className="login-content">
          <div className="form-login">
            <div className="form-content">
              <h2>Te has inscrito al evento</h2>
              <p>Recibirás en tu correo electrónico el mensaje de confirmación con el enlace para acceder.</p>
              <p className="smaller-paragraph">
                ¿No lo has recibido? Espera unos minutos o contacta con nosotros en:{" "}
                <a href="mailto: info@adigital.org">info@adigital.org</a>.
              </p>
            </div>
          </div>
          <div className="login-image">
            <img src={imageLogin} />
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div id="login">
        <div className="logo">
          <img src={imageLogo} />
        </div>

        <div className="login-content">
          <div className="form-login">
            <form
              className="form"
              onSubmit={async (e) => {
                e.preventDefault()
                const res = await sendManualInvite({
                  event_id: id,
                  form_mail: formMail,
                  form_name: formName,
                  form_surname: formSurname,
                  form_company: formCompany,
                  form_position: formPosition,
                })
                console.log(res)
                if (res.status === 200) {
                  setOk(true)
                } else {
                }
              }}
            >
              <div className="form-content">
                <h2>Bienvenido/a</h2>
                <p>Introduce tus datos para inscribirte en el evento.</p>
                <input
                  value={formMail}
                  onChange={(e) => setFormMail(e.target.value)}
                  placeholder="Correo electrónico"
                  type="text"
                />
                <input
                  value={formName}
                  onChange={(e) => setFormName(e.target.value)}
                  placeholder="Nombre"
                  type="text"
                />
                <input
                  value={formSurname}
                  onChange={(e) => setFormSurname(e.target.value)}
                  placeholder="Apellidos"
                  type="text"
                />
                <input
                  value={formCompany}
                  onChange={(e) => setFormCompany(e.target.value)}
                  placeholder="Empresa"
                  type="text"
                />
                <input
                  value={formPosition}
                  onChange={(e) => setFormPosition(e.target.value)}
                  placeholder="Cargo"
                  type="text"
                />
                <button
                  disabled={
                    formPosition === "" ||
                    formMail === "" ||
                    formName === "" ||
                    formSurname === "" ||
                    formCompany === ""
                  }
                  className={
                    formPosition === "" ||
                    formMail === "" ||
                    formName === "" ||
                    formSurname === "" ||
                    formCompany === ""
                      ? "empty-button"
                      : "ready-button"
                  }
                  type="submit"
                >
                  Inscribirse
                </button>
              </div>
            </form>
            <p className="even-smaller-paragraph">
              Tus datos serán tratados por Adigital con la finalidad de prestarte los servicios solicitados. Consulta
              más información y cómo ejercer tus derechos en nuestra{" "}
              <a href="https://www.adigital.org/politica-proteccion-datos-cookies-redes-sociales/">
                Política de privacidad
              </a>
              . Consulta los términos y condiciones en <a href="https://www.adigital.org/aviso-legal/">este enlace</a>.
            </p>
          </div>
          <div className="login-image">
            <img src={imageLogin} />
          </div>
        </div>
      </div>
    )
  }
}

export default EventManualInvite
