import React, { useEffect, useState } from "react"
import "./Logout.sass"
import imageLogin from "../../static/login-image.png"
import imageLogo from "../../static/logo-adigital-white.png"

const Logout = () => {
  useEffect(() => {
    localStorage.removeItem("accessToken")
  })

  return (
    <div id="login">
      <div className="logo">
        <img src={imageLogo} />
      </div>
      <div className="login-content">
        <div className="form-login">
          <div className="form-content">
            <h2>Has cerrado sesión en Espacio Adigital.</h2>
            <p>
              Para acceder de nuevo, pulsa <a href="https://espacioadigital.org/login">aquí</a>.
            </p>
          </div>
        </div>
        <div className="login-image">
          <img src={imageLogin} />
        </div>
      </div>
    </div>
  )
}

export default Logout
