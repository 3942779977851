import React, { useState } from "react"
import "./Scanner.sass"
import { QrReader } from "react-qr-reader"
import { checkQRCode, checkSecurityCode } from "../../hooks/api"
import imageLogo from "../../static/logo-adigital-white.png"

let lastQR = undefined

const Scanner = () => {
  const [securityCode, setSecurityCode] = useState(undefined)
  const [qrStatus, setQrStatus] = useState(undefined)
  const [status, setStatus] = useState(undefined)

  const sendQRCode = async (jwt) => {
    if (lastQR !== jwt) {
      setQrStatus("sending")
    }
    lastQR = jwt
    const res = await checkQRCode({
      jwt,
      securityCode,
    })
    console.log(await res.json())
    if (res.status === 200) {
      setQrStatus("ok")
      setTimeout(() => {
        setQrStatus(undefined)
      }, "2000")
    } else {
      setQrStatus("ko")
      setTimeout(() => {
        setQrStatus(undefined)
      }, "2000")
    }
  }
  if (!securityCode) {
    return (
      <div id="scanner">
        <div className="logo">
          <img src={imageLogo} />
        </div>
        <div className="scanner-form">
          <form
            className="form"
            onSubmit={async (e) => {
              e.preventDefault()
              const res = await checkSecurityCode({ securityCode: e.target[0].value })
              if (res.status === 200) {
                setSecurityCode(e.target[0].value)
                setStatus(undefined)
              } else {
                setStatus(res.status)
              }
              e.target.reset()
            }}
          >
            <div className="form-content">
              <h2>Código de seguridad</h2>
              <input type="text" />
              <button type="submit">Introducir</button>
            </div>
          </form>
          {status && status === 200 ? "Código de seguridad correcto." : status ? "Código de seguridad incorrecto" : ""}
        </div>
      </div>
    )
  } else {
    if (qrStatus === "ko") {
      return (
        <div className="result" id="ko-scan">
          <div className="logo">
            <img src={imageLogo} />
          </div>
          <div className="result-text">
            <div className="result-icon">X</div>
            <div>Error de verificación</div>
          </div>
        </div>
      )
    }
    if (qrStatus === "ok") {
      return (
        <div className="result" id="ok-scan">
          <div className="logo">
            <img src={imageLogo} />
          </div>
          <div className="result-text">
            <div className="result-icon">&#10003;</div>
            <div>¡Verificado!</div>
          </div>
        </div>
      )
    }
    return (
      <div id="scanner-scan">
        {qrStatus === "sending" ? "Enviando" : undefined}
        <div className="logo">
          <img src={imageLogo} />
        </div>
        <QrReader
          constraints={{
            facingMode: "environment",
          }}
          delay={3000}
          onResult={(result, error) => {
            if (!!result) {
              sendQRCode(result?.text)
            }
          }}
          videoContainerStyle={{
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            margin: "auto",
          }}
          videoStyle={{ height: "100%", objectFit: "fill" }}
        />
      </div>
    )
  }
}

export default Scanner
