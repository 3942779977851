import React from "react"
import "./TimerElement.sass"

const TimerElement = ({ time, text }) => {
  return (
    <div id="timer-element">
      <h1>{time}</h1>
      <div>{text}</div>
    </div>
  )
}

export default TimerElement
