import React, { useState } from "react"
import { sendSubmission } from "../../hooks/api"
import { useLocation } from "react-router-dom"
import "./Home.sass"
import logo from "src/static/logo-adigital-negativo.png"

const Home = () => {
  const useQuery = () => {
    const { search } = useLocation()
    return React.useMemo(() => new URLSearchParams(search), [search])
  }
  let query = useQuery()
  const [submitted, setSubmitted] = useState(false)
  const origin = query.get("origin")
  const areaUuid = query.get("area-uuid")
  const sendLogin = async (e) => {
    e.preventDefault()
    await sendSubmission({
      origin: origin,
      "area-uuid": areaUuid,
      "login-mail": e.target[0].value,
    })
    setSubmitted(true)
  }
  if (!origin || !areaUuid) {
    return <div id="home">Invalid access</div>
  }
  if (submitted) {
    return (
      <div id="home">
        <div className="form">
          <div className="header">
            <img src={logo}></img>
          </div>
          <div className="form-content">
            <h3>Acceso enviado.</h3>
            <h3>Comprueba tu correo electrónico para acceder al área privada.</h3>
          </div>
        </div>
      </div>
    )
  }
  return (
    <div id="home">
      <form className="form" onSubmit={sendLogin}>
        <div className="header">
          <img src={logo}></img>
        </div>
        <div className="form-content">
          <h2>Iniciar sesión</h2>
          <input placeholder="Correo electrónico" type="email" />
          <button type="submit">Iniciar sesión</button>
        </div>
      </form>
    </div>
  )
}

export default Home
