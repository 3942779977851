import React, { useState, useEffect } from "react"
import "./TicketingInvite.sass"
import { checkSecurityCode, copyEventInvites, getEvent, getParticipantsWithLinks, sendInvite } from "../../hooks/api"
import { useParams } from "react-router-dom"
import logo from "src/static/logo-adigital-negativo.png"
import { useHistory } from "react-router-dom"
import { useModal } from "@gluedigital/modal"
import "../TicketingCopyModal/TicketingCopyModal"

const TicketingInvite = () => {
  const [securityCode, setSecurityCode] = useState(undefined)
  const [status, setStatus] = useState(undefined)
  const [event, setEvent] = useState(undefined)
  let { id } = useParams()
  const history = useHistory()
  const modal = useModal()

  useEffect(async () => {
    if (securityCode) {
      const res = await getEvent(id, securityCode)
      setEvent(await res.json())
    }
  }, [securityCode])

  const ticketingInvite = async (email, presential) => {
    const res = await sendInvite({
      email,
      securityCode,
      ads_event_id: id,
      presential: presential,
    })
    setStatus(res.status)
    if (res.status === 200) {
      console.log("ok")
    } else {
      console.log("ko")
    }
  }

  const importEventInvites = async (id_from) => {
    const res = await copyEventInvites({
      securityCode,
      event_id_from: id_from,
      event_id_to: id,
    })
    setStatus(res.status)
    if (res.status === 200) {
      console.log("ok")
    } else {
      console.log("ko")
    }
  }

  const getTicketingInvites = async (presential) => {
    await getParticipantsWithLinks(id, securityCode, presential)
  }

  if (!securityCode) {
    return (
      <div id="ticketing-invite">
        <form
          className="form"
          id="security"
          onSubmit={async (e) => {
            e.preventDefault()
            const res = await checkSecurityCode({ securityCode: e.target[0].value })
            if (res.status === 200) {
              setSecurityCode(e.target[0].value)
              setStatus(undefined)
            } else {
              setStatus(res.status)
            }
            e.target.reset()
          }}
        >
          <div className="form-content">
            <h2>Código de seguridad</h2>
            <input name="code" type="text" />
            <button type="submit">Introducir</button>
          </div>
        </form>
        {status && status === 200 ? "Código de seguridad correcto." : status ? "Código de seguridad incorrecto" : ""}
      </div>
    )
  } else if (event !== undefined) {
    return (
      <div id="ticketing-invite">
        <div className="header">
          <img src={logo} />
        </div>
        <form
          id="invite"
          className="invite-form"
          onSubmit={(e) => {
            e.preventDefault()
            ticketingInvite(e.target.email.value, e.target.presential.checked)
            e.target.reset()
          }}
        >
          <div className="form-content">
            <h1>Invitación al evento {event.name}:</h1>
            <h2>
              <span>Email:</span> <input name="email" type="email" />
            </h2>
            <h2>
              <span>Presencial:</span> <input defaultChecked name="presential" type="checkbox" />
            </h2>
            <button className="inviteButton" type="submit">
              Invitar
            </button>
          </div>
        </form>

        <form
          id="invite"
          className="copy-form"
          onSubmit={(e) => {
            e.preventDefault()
            modal.show("ticketing-copy-modal", {
              event,
              eventIdFrom: e.target.id_from.value,
              securityCode,
              importEventInvites,
            })
            e.target.reset()
          }}
        >
          <div className="form-content">
            <h1>Copiar asistentes al evento {event.name} en Espacioadigital desde otro evento:</h1>
            <h2>
              <span>ID (en el CRM) del evento desde el que copiar:</span> <input name="id_from" type="text" />
            </h2>
            <button className="inviteButton" type="submit">
              Importar invitaciones
            </button>
          </div>
        </form>

        <button
          className="inviteButton"
          onClick={() => {
            history.push("/scanner")
          }}
        >
          Ir al Scanner de QR
        </button>
        <br />
        <button className="inviteButton" onClick={() => getTicketingInvites(true)}>
          Obtener inscripciones presenciales
        </button>
        <br />
        <button className="inviteButton" onClick={() => getTicketingInvites(false)}>
          Obtener inscripciones virtuales
        </button>
        {status && status === 200 ? "Invitación correcta" : status ? "Error" : ""}
      </div>
    )
  } else {
    return <div>...</div>
  }
}

export default TicketingInvite
