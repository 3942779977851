import { registerModal, useModal } from "@gluedigital/modal"
import React, { useEffect, useState } from "react"
import { getEvent } from "../../hooks/api"

import "./TicketingCopyModal.sass"

const TicketingCopyModal = ({ event, eventIdFrom, securityCode, importEventInvites }) => {
  const modal = useModal()
  const [eventFrom, setEventFrom] = useState(undefined)

  useEffect(async () => {
    if (!eventFrom) {
      const res = await getEvent(eventIdFrom, securityCode)
      setEventFrom(await res.json())
    }
  })

  if (!eventFrom) {
    return <div className="ticketing-copy-modal-content">...</div>
  }

  const dateEventFrom = new Date(eventFrom.fecha)
  const dateEvent = new Date(event.fecha)
  return (
    <div className="ticketing-copy-modal-content">
      <header>
        <button
          className="close-modal"
          onClick={() => {
            modal.hide()
          }}
        >
          X
        </button>
      </header>
      <section>
        ¿Confirmas que quieres copiar al evento{" "}
        <b>
          {event.name} (
          {("0" + dateEvent.getDate()).slice(-2) +
            "-" +
            ("0" + (dateEvent.getMonth() + 1)).slice(-2) +
            "-" +
            dateEvent.getFullYear()}
          )
        </b>{" "}
        los inscritos del evento{" "}
        <b>
          {eventFrom.name} (
          {("0" + dateEventFrom.getDate()).slice(-2) +
            "-" +
            ("0" + (dateEventFrom.getMonth() + 1)).slice(-2) +
            "-" +
            dateEventFrom.getFullYear()}
          )
        </b>
        ?
        <button
          className="close-modal"
          onClick={async () => {
            await importEventInvites(eventFrom.id)
            modal.hide()
          }}
        >
          Copiar los inscritos
        </button>
      </section>
    </div>
  )
}

registerModal("ticketing-copy-modal", TicketingCopyModal)
