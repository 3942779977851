import React, { useEffect, useState } from "react"
import "./Login.sass"
import { useLocation } from "react-router-dom"
import { sendSubmission, sendSubmissionRegister, validateToken } from "../../hooks/api"
import imageLogin from "../../static/login-image.png"
import imageLogo from "../../static/logo-adigital-white.png"

const Login = () => {
  const useQuery = () => {
    const { search } = useLocation()
    return React.useMemo(() => new URLSearchParams(search), [search])
  }
  function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }

  let query = useQuery()
  let [mail, setMail] = useState("")
  let [loginDone, setLoginDone] = useState(false)
  let [registerDone, setRegisterDone] = useState(false)
  let [sendingRequest, setSendingRequest] = useState(false)
  const registerToken = query.get("registerToken")
  let [formPosition, setFormPosition] = useState("")
  let [formName, setFormName] = useState("")
  let [formSurname, setFormSurname] = useState("")
  let [formAccountIndex, setFormAccountIndex] = useState("-1")
  let [loginFail, setLoginFail] = useState(false)

  const accessToken = query.get("accessToken")
    ? query.get("accessToken")
    : typeof window !== "undefined"
    ? localStorage.accessToken
    : undefined
  const redirect = query.get("redirect")

  useEffect(async () => {
    if (accessToken && typeof window !== "undefined") {
      const res = await validateToken({ jwt: accessToken })
      const tokens = await res.json()
      if (tokens.error && tokens.error === "Invalid JWT") {
        window.location.replace(window.location.protocol + "//" + window.location.host + "/error")
      } else {
        localStorage.setItem("accessToken", accessToken)
        const hivebriteUrl =
          process.env.NODE_ENV === "production"
            ? "https://espacioadigital.org"
            : "https://adigital.preprod.hivebrite.com"
        if (redirect || tokens.hivebriteToken) {
          window.location.replace(redirect ? redirect : hivebriteUrl + "/jwt_auth/sso?jwt=" + tokens.hivebriteToken)
        } else {
          window.location.replace(window.location.protocol + "//" + window.location.host + "/error")
        }
      }
    }
  })

  const customTextLoginOk = redirect ? "al evento" : "Espacio Adigital"

  if (loginFail) {
    return (
      <div id="login">
        <div className="logo">
          <img src={imageLogo} />
        </div>
        <div className="login-content">
          <div className="form-login">
            <div className="form-content">
              <h2>¡Usuario no registrado!</h2>
              <p>
                Parece que no estás registrado. Recuerda que debes acceder con tu correo electrónico de empresa. Puedes{" "}
                <a href="" onClick={() => window.location.reload()}>
                  volver a intentarlo aquí
                </a>{" "}.
              </p>
              <p>
                Si necesitas ayuda, puedes contactar con nosotros en:{" "}
                <a href="mailto: info@adigital.org">info@adigital.org</a>.
              </p>
            </div>
          </div>
          <div className="login-image">
            <img src={imageLogin} />
          </div>
        </div>
      </div>
    )
  }
  if (registerToken && !accessToken) {
    const parsedToken = JSON.parse(Buffer.from(registerToken.split(".")[1], "base64").toString("utf8"))
    return registerDone ? (
      <div id="login">
        <div className="logo">
          <img src={imageLogo} />
        </div>

        <div className="login-content">
          <div className="form-login">
            <div className="form-content">
              <h2>¡Acceso registrado!</h2>
              <p>Acabamos de enviar a tu correo electrónico el enlace para acceder a {customTextLoginOk}.</p>

              <p className="smaller-paragraph">
                ¿No lo has recibido? Espera unos minutos y{" "}
                <a href="" onClick={() => window.location.reload()}>
                  vuelve a intentarlo
                </a>{" "}
                o contacta con nosotros en: <a href="mailto: info@adigital.org">info@adigital.org</a>.
              </p>
            </div>
          </div>
          <div className="login-image">
            <img src={imageLogin} />
          </div>
        </div>
      </div>
    ) : (
      <div id="login">
        <div className="logo">
          <img src={imageLogo} />
        </div>

        <div className="login-content">
          <div className="form-login">
            <form
              className="form"
              onSubmit={async (e) => {
                setSendingRequest(true)
                e.preventDefault()
                let origin = window.location.protocol + "//" + window.location.host + "/login"
                if (redirect) {
                  origin = origin + "?redirect=" + encodeURIComponent(redirect)
                }

                const res = await sendSubmissionRegister({
                  origin: origin,
                  register_token: registerToken,
                  form_name: formName,
                  form_surname: formSurname,
                  form_position: formPosition,
                  form_index_account: formAccountIndex,
                })
                if (res.status === 200) {
                  const resBody = await res.json()
                  let accessURL =
                    window.location.protocol + "//" + window.location.host + "/login?accessToken=" + resBody.accessToken
                  if (redirect) {
                    accessURL = accessURL + "&redirect=" + encodeURIComponent(redirect)
                  }
                  window.location.replace(accessURL)
                } else {
                }
              }}
            >
              <div className="form-content">
                <p>Introduce tus datos para registrarte en Adigital.</p>
                <input value={parsedToken.mail} readOnly={true} disabled={true} type="text" />
                <input
                  value={formName}
                  onChange={(e) => setFormName(e.target.value)}
                  placeholder="Nombre"
                  type="text"
                />
                <input
                  value={formSurname}
                  onChange={(e) => setFormSurname(e.target.value)}
                  placeholder="Apellidos"
                  type="text"
                />
                <select value={formAccountIndex} onChange={(e) => setFormAccountIndex(e.target.value)}>
                  <option value={-1}>Selecciona tu empresa</option>
                  {parsedToken.names.map((name, i) => (
                    <option key={i} value={i}>
                      {name}
                    </option>
                  ))}
                </select>
                <input
                  value={formPosition}
                  onChange={(e) => setFormPosition(e.target.value)}
                  placeholder="Cargo"
                  type="text"
                />
                <button
                  disabled={
                    formAccountIndex === "-1" ||
                    formPosition === "" ||
                    formName === "" ||
                    formSurname === "" ||
                    sendingRequest === true
                  }
                  className={
                    formAccountIndex === "-1" ||
                    formPosition === "" ||
                    formName === "" ||
                    formSurname === "" ||
                    sendingRequest === true
                      ? "empty-button"
                      : "ready-button"
                  }
                  type="submit"
                >
                  {sendingRequest ? "Inscribiendo..." : "Inscribirse"}
                </button>
              </div>
            </form>
          </div>
          <div className="login-image">
            <img src={imageLogin} />
          </div>
        </div>
      </div>
    )
  } else if (!accessToken) {
    const customText = redirect
      ? "acceder al evento"
      : "iniciar sesión en Espacio Adigital, el Área Privada exclusiva para Asociados de Adigital"
    return (
      <div id="login">
        <div className="logo">
          <img src={imageLogo} />
        </div>
        {!loginDone ? (
          <div className="login-content">
            <div className="form-login">
              <form
                className="form"
                onSubmit={async (e) => {
                  setSendingRequest(true)
                  e.preventDefault()
                  let origin = window.location.protocol + "//" + window.location.host + "/login"
                  if (redirect) {
                    origin = origin + "?redirect=" + encodeURIComponent(redirect)
                  }
                  const res = await sendSubmission({
                    origin: origin,
                    "login-mail": mail.trim().toLowerCase(),
                  })
                  if (res.status === 200) {
                    setSendingRequest(false)
                    setLoginDone(true)
                  } else {
                    if (res.status === 401) {
                      setLoginFail(true)
                    }
                  }
                  e.target.reset()
                }}
              >
                <div className="form-content">
                  <h2>Bienvenido/a</h2>
                  <p>
                    Introduce tu correo electrónico para {customText}. Recibirás un correo electrónico con el enlace de
                    acceso.
                  </p>
                  <input
                    value={mail}
                    onChange={(e) => setMail(e.target.value)}
                    placeholder="Correo electrónico"
                    type="text"
                  />
                  <button
                    disabled={mail === "" || sendingRequest === true}
                    className={mail === "" || sendingRequest === true ? "empty-button" : "ready-button"}
                    type="submit"
                  >
                    Acceder
                  </button>
                  <p className="even-smaller-paragraph">
                    Tus datos serán tratados por Adigital con la finalidad de prestarte los servicios solicitados.
                    Consulta más información y cómo ejercer tus derechos en nuestra{" "}
                    <a href="https://www.adigital.org/politica-proteccion-datos-cookies-redes-sociales/">
                      Política de privacidad
                    </a>
                    . Consulta los términos y condiciones en{" "}
                    <a href="https://www.adigital.org/aviso-legal/">este enlace</a>.
                  </p>
                </div>
              </form>
            </div>
            <div className="login-image">
              <img src={imageLogin} />
            </div>
          </div>
        ) : (
          <div className="login-content">
            <div className="form-login">
              <div className="form-content">
                <h2>¡Acceso registrado!</h2>
                <p>Acabamos de enviar a tu correo electrónico el enlace para acceder a {customTextLoginOk}.</p>

                <p className="smaller-paragraph">
                  ¿No lo has recibido? Espera unos minutos y{" "}
                  <a href="" onClick={() => window.location.reload()}>
                    vuelve a intentarlo
                  </a>{" "}
                  o contacta con nosotros en: <a href="mailto: info@adigital.org">info@adigital.org</a>.
                </p>
              </div>
            </div>
            <div className="login-image">
              <img src={imageLogin} />
            </div>
          </div>
        )}
      </div>
    )
  } else {
    return <></>
  }
}

export default Login
