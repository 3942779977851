export const sendSubmission = (body) => {
  const headers = {
    "Content-Type": "application/json",
  }
  return fetch(`/api/login`, {
    headers,
    body: JSON.stringify(body),
    method: "POST",
  })
}

export const sendSubmissionRegister = (body) => {
  const headers = {
    "Content-Type": "application/json",
  }
  return fetch(`/api/register`, {
    headers,
    body: JSON.stringify(body),
    method: "POST",
  })
}

export const validateToken = (body) => {
  const headers = {
    "Content-Type": "application/json",
  }
  return fetch(`/api/token-validate`, {
    headers,
    body: JSON.stringify(body),
    method: "POST",
  })
}
export const sendEventPesence = async (body) => {
  const headers = {
    "Content-Type": "application/json",
  }
  return await fetch(`/api/send-event-presence`, {
    headers,
    body: JSON.stringify(body),
    method: "POST",
  })
}

export const sendManualInvite = async (body) => {
  const headers = {
    "Content-Type": "application/json",
  }
  return await fetch(`/api/send-manual-invite`, {
    headers,
    body: JSON.stringify(body),
    method: "POST",
  })
}

export const checkQRCode = async (body) => {
  const headers = {
    "Content-Type": "application/json",
  }
  return await fetch(`/api/check-qr-code`, {
    headers,
    body: JSON.stringify(body),
    method: "POST",
  })
}

export const sendInvite = async (body) => {
  const headers = {
    "Content-Type": "application/json",
  }
  return await fetch(`/api/event-inscription`, {
    headers,
    body: JSON.stringify(body),
    method: "POST",
  })
}

export const copyEventInvites = async (body) => {
  const headers = {
    "Content-Type": "application/json",
  }
  return await fetch(`/api/copy-event-invites`, {
    headers,
    body: JSON.stringify(body),
    method: "POST",
  })
}

export const checkSecurityCode = async (body) => {
  const headers = {
    "Content-Type": "application/json",
  }
  return await fetch(`/api/check-security-code`, {
    headers,
    body: JSON.stringify(body),
    method: "POST",
  })
}

export const getEvent = async (eventId, securityCode) => {
  const headers = {
    "Content-Type": "application/json",
  }
  return await fetch(`/api/event/${eventId}?securityCode=${securityCode}`, {
    headers,
    method: "GET",
  })
}

export const getParticipantsWithLinks = async (eventId, securityCode, presential) => {
  const headers = {
    "Content-Type": "application/json",
  }
  fetch(`/api/event/${eventId}/participants/links?securityCode=${securityCode}&presential=${presential}`, {
    headers,
    method: "GET",
  })
    .then((res) => {
      return res.blob()
    })
    .then((data) => {
      var a = document.createElement("a")
      a.href = window.URL.createObjectURL(data)
      a.download = `Inscripciones${presential ? "Presenciales" : "Virtuales"}DeEvento_${eventId}.csv`
      a.click()
    })
}
