import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import "./WaitingRoom.sass"
import { sendEventPesence } from "../../hooks/api"
import logo from "src/static/logo-adigital-negativo.png"
import TimerElement from "../../components/TimerElement/TimerElement"
import QRCode from "react-qr-code"

const WaitingRoom = () => {
  const useQuery = () => {
    const { search } = useLocation()
    return React.useMemo(() => new URLSearchParams(search), [search])
  }
  const [eventDate, setEventDate] = useState(undefined)
  const [eventPresential, setEventPresential] = useState(undefined)
  const [error, setError] = useState(undefined)
  let query = useQuery()
  const accessToken = query.get("accessToken")
    ? query.get("accessToken")
    : typeof window !== "undefined"
    ? localStorage.accessToken
    : undefined
  const inscriptionToken = query.get("inscriptionToken")
  const eventId = query.get("eventId")

  const [countDown, setCountDown] = useState(undefined)
  useEffect(async () => {
    if (inscriptionToken || (eventId && accessToken)) {
      const presenceRes = await sendEventPesence({ inscriptionToken, eventId, accessToken })
      console.log(presenceRes.status)
      const dateJson = await presenceRes.json()
      if (dateJson.presential) {
        setEventPresential(true)
      } else {
        setEventPresential(false)
      }
      if (presenceRes.status === 403) {
        window.location.replace(window.location.protocol + "//" + window.location.host + "/error")
      } else if (presenceRes.status !== 200) {
        setError("Link inválido")
      } else {
        setEventDate(new Date(dateJson.fecha).getTime())
        setCountDown(new Date(dateJson.fecha).getTime() - new Date().getTime())
      }
    } else {
      window.location.replace(
        window.location.protocol +
          "//" +
          window.location.host +
          "/login?redirect=" +
          encodeURIComponent(window.location.protocol + "//" + window.location.host + "/waiting?eventId=" + eventId)
      )
    }
  }, [])

  useEffect(() => {
    const interval = setInterval(async () => {
      if (eventDate) {
        const timeLeft = eventDate - new Date().getTime()
        if (timeLeft >= 0) {
          setCountDown(timeLeft)
        } else if (document.hasFocus() && !eventPresential) {
          const presenceRes = await sendEventPesence({ inscriptionToken, eventId, accessToken })
          const dateJson = await presenceRes.json()
          if (dateJson.error && dateJson.link !== undefined) {
            setError("Link inválido")
          } else {
            window.location.href = dateJson.link
          }
        }
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [eventDate])

  let content
  if (error) {
    content = <h1>{error}</h1>
  } else if (eventPresential) {
    content = (
      <div className="qr-section">
        <QRCode value={inscriptionToken} />
      </div>
    )
  } else if (!countDown) {
    content = <h2>Cargando datos del evento...</h2>
  } else if (countDown && countDown < 0) {
    content = <h2>Accediendo al evento...</h2>
  } else {
    const getDrawValues = (countDown) => {
      const days = Math.floor(countDown / (1000 * 60 * 60 * 24))
      const hours = Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60))
      const seconds = Math.floor((countDown % (1000 * 60)) / 1000)

      return [days, hours, minutes, seconds]
    }
    const drawValues = getDrawValues(countDown)
    content = (
      <div>
        <h2>Se accederá al evento en:</h2>
        <div id="timers">
          <TimerElement time={drawValues[0]} text={"Días"} />
          <TimerElement time={drawValues[1]} text={"Horas"} />
          <TimerElement time={drawValues[2]} text={"Minutos"} />
          <TimerElement time={drawValues[3]} text={"Segundos"} />
        </div>
      </div>
    )
  }
  return (
    <div id="waiting">
      <div className="header">
        <img src={logo} />
      </div>
      {content}
    </div>
  )
}

export default WaitingRoom
